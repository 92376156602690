import {
	Datagrid,
	DeleteButton,
	Edit,
	EditButton,
	List,
	ReferenceArrayInput,
	SimpleForm,
	SimpleList,
	TextField,
	TextInput,
	ArrayField,
	CheckboxGroupInput,
	ChipField,
	Create,
	required,
	SingleFieldList,
} from 'react-admin';
import React from 'react';
import { useMediaQuery } from '@mui/material';
import { CreateToolbar, TopBarActions } from './components';
import { styled } from '@mui/material/styles';

const UgTitle = styled('div')({
	fontFamily: 'UgFont',
});

const groupBy = (permissions, f) => {
	let groups = {};
	permissions.forEach((o) => {
		let group = JSON.stringify(f(o));
		groups[group] = groups[group] || [];
		groups[group].push(o);
	});
	return Object.keys(groups).map((group) => {
		return groups[group];
	});
};

const optionText = (record) =>
	`${record?.description.zh} ${record?.description.ug}`;

function sortClass(sortData) {
	const sorted = groupBy(sortData, (item) => {
		return item.group?.zh; // 返回需要分组的对象
	});
	return sorted;
}

const GroupInput = ({ choices, ...props }) => {
	const list = sortClass(choices);
	if (list.length > 0) {
		return list.map((l, idx) => {
			return (
				<CheckboxGroupInput
					{...props}
					key={idx}
					label={l[0].group?.zh}
					choices={l}
					optionText={optionText}
					optionValue='id'
				/>
			);
		});
	}
	return null;
};

const RolesList = (props) => {
	const isSmall = useMediaQuery('(max-width:600px)');
	return (
		<List {...props} title='角色管理'>
			{isSmall ? (
				<SimpleList
					primaryText={(record) => record.name}
					secondaryText={(record) => (
						<span className={UgTitle}>{record.display_name}</span>
					)}
					tertiaryText={(record) => (
						<span className={UgTitle}>{record.description}</span>
					)}
				/>
			) : (
				<Datagrid>
					<TextField source='name' label='角色名称' />
					<TextField
						source='description'
						label='角色描述'
						className={UgTitle}
					/>
					<TextField
						source='display_name'
						label='显示名称'
						className={UgTitle}
					/>
					<ArrayField source='permissions.data' label='角色权限'>
						<SingleFieldList>
							<ChipField source='group.zh' />
						</SingleFieldList>
					</ArrayField>
					<EditButton label='编辑' />
					<DeleteButton
						label='删除'
						mutationMode='pessimistic'
						confirmTitle=''
					/>
				</Datagrid>
			)}
		</List>
	);
};

const RolesEdit = (props) => {
	return (
		<Edit title='角色编辑' actions={<TopBarActions />} {...props}>
			<SimpleForm>
				<TextInput source='name' label='角色名称' />
				<TextInput source='description' label='角色描述' />
				<TextInput source='display_name' label='显示名称' />
				<ReferenceArrayInput
					source='permissions.data'
					reference='permissions'
					label='权限'
					validate={required()}
					allowEmpty
					fullWidth
					format={(value) => value.map((i) => i.id ?? i)}
				>
					<GroupInput />
				</ReferenceArrayInput>
			</SimpleForm>
		</Edit>
	);
};

const RolesCreate = (props) => {
	return (
		<Create title='角色新建' {...props}>
			<SimpleForm toolbar={<CreateToolbar />}>
				<TextInput source='name' label='角色名称' />
				<TextInput source='description' label='角色描述' />
				<TextInput source='display_name' label='显示名称' />
				<ReferenceArrayInput
					source='permissions'
					reference='permissions'
					label='权限'
					validate={required()}
					allowEmpty
					fullWidth
				>
					<GroupInput />
				</ReferenceArrayInput>
			</SimpleForm>
		</Create>
	);
};

export { RolesList, RolesEdit, RolesCreate };
