import {
  Box,
  Card,
  CardContent,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import {
  DateField,
  NumberField,
  TextField,
  useGetList,
  useGetOne,
} from "react-admin";
import { useParams } from "react-router-dom";
import {
  Bar,
  BarChart,
  CartesianGrid,
  Legend,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";

export const SurveyStats = () => {
  const { id } = useParams();
  const { data: survey } = useGetOne("surveys", { id });
  const { data: responses, isLoading } = useGetList(`surveys/${id}/responses`, {
    pagination: { page: 1, perPage: 20 },
  });

  if (isLoading) return null;

  // Basic statistics
  const totalResponses = responses?.length || 0;
  const completedResponses =
    responses?.filter((r) => r.status === 1).length || 0;
  const averageTimeSpent =
    responses?.reduce((acc, r) => acc + r.time_spent, 0) / totalResponses || 0;

  return (
    <Box>
      <Card>
        <CardContent>
          <Typography variant="h5">问卷统计概览</Typography>
          <Box display="flex" gap={4} my={2}>
            <StatBox title="总回复数" value={totalResponses} />
            <StatBox
              title="完成率"
              value={`${((completedResponses / totalResponses) * 100).toFixed(1)}%`}
            />
            <StatBox
              title="平均用时"
              value={`${(averageTimeSpent / 60).toFixed(1)}分钟`}
            />
          </Box>
        </CardContent>
      </Card>

      <Card sx={{ mt: 2 }}>
        <CardContent>
          <Typography variant="h6">每日回复趋势</Typography>
          <ResponsesTimeChart data={responses} />
        </CardContent>
      </Card>

      <Card sx={{ mt: 2 }}>
        <CardContent>
          <Typography variant="h6">最近回复</Typography>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>用户</TableCell>
                <TableCell>开始时间</TableCell>
                <TableCell>完成时间</TableCell>
                <TableCell>用时</TableCell>
                <TableCell>获得积分</TableCell>
                <TableCell>状态</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {responses?.slice(0, 10).map((response) => (
                <TableRow key={response.id}>
                  <TableCell>
                    <TextField source="member_id" record={response} />
                  </TableCell>
                  <TableCell>
                    <DateField source="started_at" record={response} showTime />
                  </TableCell>
                  <TableCell>
                    <DateField
                      source="completed_at"
                      record={response}
                      showTime
                    />
                  </TableCell>
                  <TableCell>{formatDuration(response.time_spent)}</TableCell>
                  <TableCell>
                    <NumberField source="points_awarded" record={response} />
                  </TableCell>
                  <TableCell>
                    {response.status === 1 ? "已完成" : "进行中"}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </CardContent>
      </Card>
    </Box>
  );
};

// Helper components
const StatBox = ({ title, value }) => (
  <Box textAlign="center" p={2} bgcolor="background.paper" borderRadius={1}>
    <Typography variant="h4" color="primary">
      {value}
    </Typography>
    <Typography variant="body2" color="textSecondary">
      {title}
    </Typography>
  </Box>
);

const ResponsesTimeChart = ({ data }) => {
  const chartData = processTimeChartData(data);

  return (
    <BarChart width={800} height={300} data={chartData}>
      <CartesianGrid strokeDasharray="3 3" />
      <XAxis dataKey="date" />
      <YAxis />
      <Tooltip />
      <Legend />
      <Bar dataKey="responses" fill="#8884d8" name="回复数" />
      <Bar dataKey="completed" fill="#82ca9d" name="完成数" />
    </BarChart>
  );
};

// Helper functions
const formatDuration = (seconds) => {
  const minutes = Math.floor(seconds / 60);
  const remainingSeconds = seconds % 60;
  return `${minutes}分${remainingSeconds}秒`;
};

const processTimeChartData = (responses) => {
  // Group responses by date
  const groupedData = responses?.reduce((acc, response) => {
    const date = new Date(response.started_at).toLocaleDateString();
    if (!acc[date]) {
      acc[date] = { responses: 0, completed: 0 };
    }
    acc[date].responses++;
    if (response.status === 1) {
      acc[date].completed++;
    }
    return acc;
  }, {});

  return Object.entries(groupedData).map(([date, stats]) => ({
    date,
    ...stats,
  }));
};
