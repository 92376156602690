import { stringify } from "qs";
import axios from "axios";
import { useNavigate } from "react-router-dom";
class HttpError extends Error {
  constructor(message, status, data = null) {
    super(message);
    this.name = 'HttpError';
    this.status = status;
    this.message = message;
    this.data = data;
  }
}
export const baseURL =
  process.env.NODE_ENV === "production" || true
    ? "https://api.mms.bulutbazar.com/v1/"
    : "/v1/";
const httpClient = axios.create({
  baseURL,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/x-www-form-urlencoded",
  },
});

export const convertFileToBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => resolve(reader.result);
    reader.onerror = reject;
    reader.readAsDataURL(file.rawFile);
  });

const withImageResource = [
  "articles",
  "advertisements",
  "member/levels",
  "member/gifts",
];

httpClient.interceptors.request.use(
  function (config) {
    const auth = JSON.parse(localStorage.getItem("auth"));
    if (auth && auth.access_token) {
      return {
        ...config,
        headers: {
          ...config.headers,
          Authorization: `Bearer ${auth.access_token}`,
        },
      };
    }
    return config;
  },
  function (error) {
    return Promise.reject(error.message);
  }
);

httpClient.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    const { response } = error;
    const { data, status } = response;
    const res = { code: 400, data: data.message, status };
    return res;
  }
);

const dataProvider = {
  getList: (resource, params) => {
    const { page, perPage } = params.pagination;
    const { field, order } = params.sort;
    const query = {
      orderBy: field,
      sortedBy: order,
      page,
      limit: perPage > 100 ? 0 : perPage,
    };
    if (resource === "advertisements") {
      if (params.filter.type) {
        let type = params.filter.type;
        delete params.filter.type;
        query.search = `type:${type}`;
      }
    }
    if (resource === "member/birthdays") {
      if (!params.filter.type) {
        const birthday = params.filter.birthday.slice(5);
        delete params.filter.birthday;
        query.search = `birthday:${birthday};`;
        // query.searchJoin = "and";
      }
    }

    if (resource === "supermarket/show") {
      const id = localStorage.getItem("supermarketId");
      resource = `supermarket/coupons/${id}/lists`;
      if (params.filter.is_audited === 0 || params.filter.is_audited === 1) {
        const is_audited = params.filter.is_audited;
        delete params.filter.is_audited;
        query.is_audited = is_audited;
      }
      if (params.filter.is_sended === 0 || params.filter.is_sended === 1) {
        const is_sended = params.filter.is_sended;
        delete params.filter.is_sended;
        query.is_sended = is_sended;
      }
    }
    if (resource === "members") {
      if (params.filter.register) {
        const start_at = params.filter.register.start_at;
        const end_at = params.filter.register.end_at;
        delete params.filter.register;
        query.start_at = start_at;
        query.end_at = end_at;
      }
      if (params.filter.birthday) {
        const birthday_start = params.filter.birthday.birthday_start;
        const birthday_end = params.filter.birthday.birthday_end;
        delete params.filter.birthday;
        query.birthday_start = birthday_start;
        query.birthday_end = birthday_end;
      }
      if (params.filter.points) {
        const points_start = params.filter.points.points_start;
        const points_end = params.filter.points.points_end;
        delete params.filter.points;
        query.points_start = points_start;
        query.points_end = points_end;
      }
    }
    if (resource === "parking/orders") {
      if (params.filter.start_at) {
        const start_at = params.filter.start_at;
        delete params.filter.start_at;
        query.start_at = start_at;
      }
      if (params.filter.end_at) {
        const end_at = params.filter.end_at;
        delete params.filter.end_at;
        query.end_at = end_at;
      }
      if (params.filter.birthday_start) {
        const birthday_start = params.filter.birthday_start;
        delete params.filter.birthday_start;
        query.birthday_start = birthday_start;
      }
      if (params.filter.birthday_end) {
        const birthday_end = params.filter.birthday_end;
        delete params.filter.birthday_end;
        query.birthday_end = birthday_end;
      }
      if (params.filter.points_start) {
        const points_start = params.filter.points_start;
        delete params.filter.points_start;
        query.points_start = points_start;
      }
      if (params.filter.points_end) {
        const points_end = params.filter.points_end;
        delete params.filter.points_end;
        query.points_end = points_end;
      }
    }
    if (resource === "ponit/histories") {
      if (params.filter.type === 0) {
        localStorage.setItem("report", JSON.stringify(params.filter));
        query.search = `type:0;point_type:wechat-auto-points`;
        query.searchJoin = "and";
        if (params.filter.start_at && params.filter.start_at !== "") {
          query.start_at = params.filter.start_at;
        }
        if (params.filter.end_at && params.filter.end_at !== "") {
          query.end_at = params.filter.end_at;
        }
        if (params.filter.mobile && params.filter.mobile !== "") {
          query.mobile = params.filter.mobile;
        }
        if (params.filter.comment && params.filter.comment !== "") {
          query.search += `;comment:${params.filter.comment}`;
        }
        const url = `${resource}?${stringify(query)}`;
        return httpClient(url).then(({ data: repData }) => {
          const {
            data,
            meta: { pagination },
          } = repData;
          return {
            data: data ?? [],
            total: pagination ? pagination.total : null,
          };
        });
      }

      if (!params.filter.type) {
        query.search = "type:0";
        query.searchJoin = "and";
      } else {
        query.search = "type:1";
        query.searchJoin = "and";
      }
      if (params.filter.point_type) {
        const point_type = params.filter.point_type;
        delete params.filter.point_type;
        query.search += ";point_type:" + point_type;
        query.searchJoin = "and";
      }
      if (params.filter.comment) {
        const comment = params.filter.comment;
        delete params.filter.comment;
        query.search += ";comment:" + comment;
      }
      if (params.filter.start_at) {
        const start_at = params.filter.start_at;
        delete params.filter.start_at;
        query.start_at = start_at;
      }
      if (params.filter.mobile) {
        const mobile = params.filter.mobile;
        delete params.filter.mobile;
        query.mobile = mobile;
      }
      if (params.filter.end_at) {
        const end_at = params.filter.end_at;
        delete params.filter.end_at;
        query.end_at = end_at;
      }
    }

    if (params.filter && Object.keys(params.filter).length > 0) {
      let tmp = Object.keys(params.filter).reduce((sum, k, idx) => {
        sum += `${k}:${params.filter[k]};`;

        return sum;
      }, "");
      tmp = tmp.substring(0, tmp.length - 1);
      query.search = tmp;
      // query.searchJoin = "and";
    }

    const url = `${resource}?${stringify(query)}`;
    return httpClient(url).then(({ data: repData }) => {
      const {
        data,
        meta: { pagination },
      } = repData;
      return {
        data: data ?? [],
        total: pagination ? pagination.total : null,
      };
    });
  },

  getOne: (resource, params) => {
    if (resource === "admins") {
      resource = "users";
    }
    if (resource === "supermarket/show") {
      resource = "supermarket/coupons/lists";
    }
    return httpClient(`${resource}/${params.id}`).then(({ data }) => ({
      data: data?.data,
    }));
  },
  getMany: (resource, params) => {
    const url = `${resource}?ids=${params.ids}`;
    return httpClient(url).then(({ data }) => ({
      data: data ? data.data : [],
    }));
  },

  getManyReference: (resource, params) => {
    const { page, perPage } = params.pagination;
    const { field, order } = params.sort;
    const query = {
      orderBy: field,
      sortedBy: order,
      page,
      limit: perPage,
    };
    const url = `${resource}?${stringify(query)}`;

    return httpClient(url).then(({ data: resData }) => {
      const {
        data,
        meta: { pagination },
      } = resData;
      return {
        data: data?.data,
        total: pagination ? pagination.total : null,
      };
    });
  },

  update: async (resource, params) => {
    if (resource === "shops" && params.data.logo.rawFile instanceof File) {
      return convertFileToBase64(params.data.logo).then((base64Pictures) =>
        httpClient(`${resource}/${params.id}`, {
          ...params,
          method: "PATCH",
          data: stringify({
            ...params.data,
            logo: base64Pictures,
          }),
        }).then(({ data }) => ({
          data: data?.data,
        }))
      );
    }
    if (withImageResource.includes(resource)) {
      const { image, ...others } = params.data;
      if (resource === "advertisements") {
        if (others.url !== "/pages/articleDetail/index") {
          others.article_id = null;
        }
      }
      let tmp = {};
      if (image.ug.rawFile instanceof File) {
        tmp.ug_image = await convertFileToBase64(image.ug);
      }
      if (image.zh.rawFile instanceof File) {
        tmp.zh_image = await convertFileToBase64(image.zh);
      }

      return httpClient(`${resource}/${params.id}`, {
        ...params,
        method: "PATCH",
        data: stringify({
          ...others,
          ...tmp,
        }),
      }).then(({ data }) => ({
        data: data?.data,
      }));
    }

    if (resource === "admins") {
      let tmp = {
        ...params.data,
        role_id: params.data.roles.data,
      };
      return httpClient(`${resource}/${params.id}`, {
        method: "PUT",
        data: stringify(tmp),
      }).then(({ data }) => ({ data: data?.data }));
    }

    if (resource === "supermarket/show") {
      resource = "supermarket/coupons/lists";
    }

    if (resource === "roles") {
      let tmp = {
        ...params.data,
        permissions: params.data.permissions.data,
      };
      return httpClient(`${resource}/${params.id}`, {
        method: "PUT",
        data: stringify(tmp),
      }).then(({ data }) => ({ data: data?.data }));
    }

    return httpClient(`${resource}/${params.id}`, {
      method: "PATCH",
      data: stringify(params.data),
    }).then(({ data }) => ({ data: data?.data }));
  },

  updateMany: (resource, params) => {
    const query = {
      search: JSON.stringify({ id: params.ids }),
    };
    return httpClient(`${resource}?${stringify(query)}`, {
      method: "PUT",
      data: stringify(params.data),
    }).then(({ data }) => ({ data }));
  },

  create: (resource, params) => {
    console.log("🚀 ~ }).then ~ params:", params)
    if (resource === "shops" && params.data.logo) {
      return convertFileToBase64(params.data.logo).then((base64Pictures) =>
        httpClient(resource, {
          ...params,
          method: "POST",
          data: stringify({
            ...params.data,
            logo: base64Pictures,
          }),
        }).then(({ data }) => ({
          data: { ...params.data, id: data.data.id },
        }))
      );
    }
    if (resource === "supermarket/show") {
      const id = localStorage.getItem("supermarketId");
      if (!params.data.state || params.data.state === false) {
        resource = `supermarket/coupons/${id}/lists`;
      } else {
        resource = `supermarket/coupons/${id}/generate`;
      }
    }
    if (withImageResource.includes(resource)) {
      if (resource === "member/gifts") {
        const giftCoupon = JSON.parse(localStorage.getItem("giftCoupon"));
        if (giftCoupon) {
          const type =
            giftCoupon?.object === "Coupon"
              ? "coupon_id"
              : "supermarket_coupon_id";
          params.data[type] = giftCoupon?.id;
        }
      }
      const { image, ...others } = params.data;
      return Promise.all([image.ug, image.zh].map(convertFileToBase64)).then(
        (base64Pictures) =>
          httpClient(resource, {
            ...params,
            method: "POST",
            data: stringify({
              ...others,
              ug_image: base64Pictures[0],
              zh_image: base64Pictures[1],
            }),
          }).then(({ data }) => ({
            data: {
              ...others,
              ug_image: base64Pictures[0],
              zh_image: base64Pictures[1],
              id: data.data.id,
            },
          }))
      );
    }
    return httpClient(resource, {
      method: "POST",
      data: stringify(params.data),
    }).then((response) => {
      console.log("🚀 ~ httpClient data:", response.errors);
      const { data } = response;
      if (response.status === 422) {
        throw new HttpError(response.data.message, 422, response.errors );
      }else if (response.status.startsWith("2")){
        throw new HttpError(response.data.message, response.status, response.data);
      }
      return ({
        data: { ...params.data, id: data.data?.id },
      })

    });
  },

  delete: (resource, params) => {
    if (resource === "supermarket/show") {
      resource = "supermarket/coupons/lists";
    }

    return httpClient(`${resource}/${params.id}`, {
      method: "DELETE",
    }).then(({ data }) => ({ data }));
  },

  deleteMany: (resource, params) => {
    const query = {
      search: JSON.stringify({ id: params.ids }),
    };
    if (resource === "supermarket/show") {
      resource = "supermarket/coupons/lists";
    }
    return httpClient(`${resource}?${stringify(query)}`, {
      method: "DELETE",
    }).then(({ data }) => ({ data }));
  },
};

export { httpClient };

export default dataProvider;
