import {
  Show,
  SimpleShowLayout,
  DateField,
  NumberField,
  ArrayField,
  Datagrid,
  FunctionField,
  useRecordContext,
} from 'react-admin';
import { Typography, Card, CardContent, Chip } from '@mui/material';
import { styled } from '@mui/material/styles';

// Replace makeStyles with styled components
const StyledSection = styled(Card)(({ theme }) => ({
  marginBottom: theme.spacing(2),
}));


const QuestionTypeChip = () => {
  const record = useRecordContext();
  const typeMap = {
    1: { label: '单选题', color: 'primary' },
    2: { label: '多选题', color: 'error' },
    3: { label: '文本题', color: 'default' },
    4: { label: '评分题', color: 'default' },
  };

  const type = typeMap[record?.type] || typeMap[1];
  return <Chip label={type.label} color={type.color} size="small" />;
};

const SurveyStatusChip = () => {
  const record = useRecordContext();
  const statusMap = {
    0: { label: '草稿', color: 'default' },
    1: { label: '已发布', color: 'primary' },
    2: { label: '已关闭', color: 'error' },
  };

  const status = statusMap[record?.status] || statusMap[0];
  return <Chip label={status.label} color={status.color} size="small" />;
};

export const SurveyShow = () => {
  return (
    <Show title="查看问卷调查">
      <SimpleShowLayout>
        <StyledSection>
          <CardContent>
            <Typography variant="h6" gutterBottom>基本信息</Typography>
            <FunctionField label="调查名称(中文)" render={record => record.name.zh} />
            <FunctionField label="调查名称(维文)" render={record => record.name.ug} />
            <NumberField source="points" label="完成积分" />
            <FunctionField label="状态" render={record => <SurveyStatusChip record={record} />} />
            <DateField source="start_date" label="开始日期" />
            <DateField source="end_date" label="结束日期" />
          </CardContent>
        </StyledSection>

        <StyledSection>
          <CardContent>
            <Typography variant="h6" gutterBottom>问题列表</Typography>
            <ArrayField source="questions.data">
              <Datagrid bulkActionButtons={false}>
                <NumberField source="order" label="序号" />
                <FunctionField label="问题内容(中文)" render={record => record.content.zh} />
                <FunctionField label="问题内容(维文)" render={record => record.content.ug} />
                <FunctionField label="类型" render={record => <QuestionTypeChip record={record} />} />

                <ArrayField source="options.data">
                  <Datagrid bulkActionButtons={false}>
                    <NumberField source="order" label="序号" />
                    <FunctionField label="选项内容(中文)" render={record => record.content.zh} />
                    <FunctionField label="选项内容(维文)" render={record => record.content.ug} />
                  </Datagrid>
                </ArrayField>
              </Datagrid>
            </ArrayField>
          </CardContent>
        </StyledSection>
      </SimpleShowLayout>
    </Show>
  );
};

export default SurveyShow; 