import {
	Datagrid,
	SimpleList,
	TextField,
	DateField,
	ListContextProvider,
	useListController,
	ImageField,
	Filter,
	DateInput,
	TextInput,
	SelectField,
} from 'react-admin';
import { Pagination } from './components';
import { useMediaQuery } from '@mui/material';
import useStyles from './PointHistoryStyle';

export const ReportList = (props) => {
	const lastData = JSON.parse(localStorage.getItem('report'));
	const classes = useStyles(props);
	const isSmall = useMediaQuery('(max-width:600px)');

	return (
		<ListContextProvider
			title='消费明细报表'
			value={useListController({
				sort: { field: 'created_at', order: 'desc' },
				basePath: props.match?.path,
				resource: 'ponit/histories',
				filter: { type: 0 },
				filterDefaultValues: {
					start_at: lastData?.start_at || '',
					end_at: lastData?.end_at || '',
					mobile: lastData?.mobile || '',
					comment: lastData?.comment || '',
				},
			})}
		>
			<div style={{ display: 'flex' }}>
				<div style={{ flex: '1' }}>
					<div>
						<Filter {...props}>
							<DateInput label='开始日期' source='start_at' />
							<DateInput label='结束日期' source='end_at' />
							<TextInput

								label='手机号搜索'
								source='mobile'
								resettable
							/>
							<TextInput

								label='店铺搜索'
								source='comment'
								resettable
							/>
						</Filter>
					</div>
				</div>
			</div>
			{isSmall ? (
				<SimpleList
					leftAvatar={(record) => (
						<div className={classes.avatar}>
							<img
								src={record?.wechat?.data.avatarUrl}
								alt={record?.wechat?.data.nickName}
							/>
						</div>
					)}
					primaryText={(record) => <span>积分{record?.amount} </span>}
					secondaryText={(record) => <span>时间 {record?.created_at}</span>}
				/>
			) : (
				<Datagrid>
					<div className={classes.avatarWrapper}>
						<ImageField
							className={classes.avatar}
							source='wechat.data.avatarUrl'
							label='用户头像'
						/>
						<TextField source='wechat.data.nickName' label='用户昵称' />
					</div>
					<TextField source='member.data.name' label='姓名' />
					<SelectField
						source='member.data.gender'
						label='性别'
						choices={[
							{ id: 0, name: '未指定' },
							{ id: 1, name: '男' },
							{ id: 2, name: '女' },
						]}
					/>

					<TextField source='card_no' label='卡号' />
					<TextField source='member.data.mobile' label='电话' />
					<TextField source='member.data.birthday' label='生日' />
					<TextField source='member.data.generation' label='年份' />
					<TextField source='shop_category.zh' label='品类' />
					<TextField source='floor.zh' label='楼层' />
					<TextField source='comment' label='消费店铺' />
					<TextField source='total_payment' label='消费金额' />
					<TextField source='amount' label='消费积分' />
					<TextField source='from_shop' label='办卡店铺' />
					<TextField source='pay_type' label='支付方式' />
					<DateField
						source='created_at'
						label='消费日期+时间'
						locales='zh-CN'
						showTime
					/>
				</Datagrid>
			)}
			<Pagination />
		</ListContextProvider>
	);
};
