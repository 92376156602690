import SendIcon from "@mui/icons-material/Send";
import { useMediaQuery } from "@mui/material";
import { useState } from "react";
import {
  Button,
  Datagrid,
  DeleteButton,
  EditButton,
  Filter,
  ImageField,
  List,
  RadioButtonGroupInput,
  SelectField,
  SimpleList,
  useRecordContext,
} from "react-admin";
import { httpClient } from "../http";
import { adTypes, adUrls } from "./helpers";

const PointsFilter = (props) => (
  <Filter {...props}>
    <RadioButtonGroupInput label="广告类型" source="type" choices={adTypes} />
    {/* <DateInput label="开始日期" source="start_at" /> */}
  </Filter>
);

const SendStatus = ({ source, ...props }) => {
  const record = useRecordContext();
  const [status, setStatus] = useState(record.sent_at);
  return status ? (
    <div>已推送</div>
  ) : (
    <Button
      {...props}
      startIcon={<SendIcon />}
      color="primary"
      onClick={async () => {
        const res = await httpClient.get(
          `advertisement/${record.id}/send/notification`,
          {},
        );
        if (res.status >= 200 && res.status < 300) {
          setStatus("send");
        }
      }}
    />
  );
};

const AdvertisementsList = (props) => {
  const isSmall = useMediaQuery("(max-width:600px)");

  return (
    <List title="广告" {...props} filters={<PointsFilter />}>
      {isSmall ? (
        <SimpleList
          primaryText={(record) => record.url}
          secondaryText={(record) => record.type}
        />
      ) : (
        <Datagrid>
          <ImageField source="image.zh" label="中文图片" />
          <ImageField source="image.ug" label="维文图片" />
          <SelectField source="type" label="广告类型" choices={adTypes} />
          <SelectField source="url" label="连接页面" choices={adUrls} />
          <SendStatus source="send_at" label="广告推送" />
          <EditButton />
          <DeleteButton />
        </Datagrid>
      )}
    </List>
  );
};

export default AdvertisementsList;