import {
	SimpleForm,
	NumberInput,
	TextInput,
	useCreate,
	SaveButton,
	Toolbar,
	useRedirect,
	useNotify,
	Edit,
	useUpdate,
	SelectInput,
	ImageInput,
	ImageField,
	ReferenceInput,
	AutocompleteInput,
	//   ReferenceInput,
	//   AutocompleteInput,
} from 'react-admin';
import { IconButton, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import CloseIcon from '@mui/icons-material/Close';
import { useFormContext } from 'react-hook-form';
import { useRef } from 'react';

const Root = styled('div')(({ theme }) => ({
	paddingTop: 50,
}));

const Title = styled('div')(({ theme }) => ({
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'space-between',
	margin: '1em',
}));

const Form = styled('div')(({ theme }) => ({
	[theme.breakpoints.up('xs')]: {
		width: 500,
	},
	[theme.breakpoints.down('xs')]: {
		width: '100vw',
	},
}));

const InlineField = styled('div')({
	display: 'inline-block',
	width: '50%',
});

const PointUpdateToolbar = ({ recordId, onClose, ...props }) => {
	const [updatePoint] = useCreate(`member/${recordId}/mannually/points`);
	const [updateRecord] = useUpdate();
	const { getValues } = useFormContext();
	const notify = useNotify();
	const handleSave = useRef();

	const convertFileToBase64 = (file) =>
		new Promise((resolve, reject) => {
			const reader = new FileReader();
			reader.onload = () => resolve(reader.result);
			reader.onerror = reject;
			reader.readAsDataURL(file.rawFile);
		});

	handleSave.current = async (e) => {
		e.preventDefault(); // necessary to prevent default SaveButton submit logic
		const { amount, comment, imagee } = getValues();

		if (!imagee) {
			return notify('请上传凭证！', { type: 'error' });
		}
		const image = await convertFileToBase64(imagee);
		updatePoint(
			`member/${recordId}/mannually/points`,
			{
				data: { amount, comment, image },
			},
			{
				onSuccess: ({ data: newRecord }) => {
					console.log('🚀 ~ handleSave.current= ~ data:', newRecord);
					notify('ra.notification.created', 'info', {
						smart_count: 1,
					});

					onClose();
				},
			}
		);
	};

	return (
		<Toolbar {...props}>
			<SaveButton
				label='保存'
				onClick={handleSave.current}
				invalid={props.invalid}
			/>
		</Toolbar>
	);
};

const PointDelToolbar = ({ recordId, onClose, ...props }) => {
	const [updatePoint] = useCreate(`members/${recordId}/clear/points`);
	const [updateRecord] = useUpdate();
	const notify = useNotify();
	const { getValues } = useFormContext();
	const handleSave = useRef();

	handleSave.current = (e) => {
		e.preventDefault(); // necessary to prevent default SaveButton submit logic
		const { amount, comment } = getValues();
		console.log('🚀 ~ PointDelToolbar ~ amount, comment:', amount, comment);
		updatePoint(
			`members/${recordId}/clear/points`,
			{
				data: { amount, comment },
			},
			{
				onSuccess: ({ data: newRecord }) => {
					notify('ra.notification.created', 'info', {
						smart_count: 1,
					});

					onClose();
				},
			}
		);
	};

	return (
		<Toolbar {...props}>
			<SaveButton
				label='保存'
				onClick={handleSave.current}
				invalid={props.invalid}
			/>
		</Toolbar>
	);
};

const genderTypes = [
	{ id: 0, name: '未指定' },
	{ id: 1, name: '男' },
	{ id: 2, name: '女' },
];

const filterToQuery = (searchText) => ({ name: searchText });

const nameMatch = (filter, choice) =>
	choice.name && choice.name.zh && choice.name.zh.includes(`${filter}`);

const inputNumber = (e) => {
	e.target.value = e.target.value.replace(/[^0-9.]/g, '');
};

const PointEdit = ({ onClose, type, ...props }) => {
	console.log('🚀 ~ PointEdit ~ type:', props);
	if (!props.id) {
		return null;
	}
	if (type === 'member') {
		return <MemberEdit onClose={onClose} {...props} type={type} />;
	} else if (type === 'decrement') {
		return <PointDecrement onClose={onClose} {...props} type={type} />;
	} else {
		return <PointSupplement onClose={onClose} {...props} type={type} />;
	}
};

const PointSupplement = ({ onClose, type, ...props }) => {
	if (!props.id) {
		return null;
	}
	return (
		<Root>
			<Title>
				<Typography variant='h6'>补录积分</Typography>
				<IconButton onClick={onClose}>
					<CloseIcon />
				</IconButton>
			</Title>

			<Edit {...props} title='补录积分'>
				<SimpleForm
					className={Form}
					toolbar={<PointUpdateToolbar onClose={onClose} recordId={props.id} />}
				>
					<ReferenceInput
						reference='shops'
						source='shop_id'
						label='店铺'
						isRequired
					>
						<AutocompleteInput
							filterToQuery={filterToQuery}
							optionText='name.zh'
							optionValue='id'
							matchSuggestion={nameMatch}
						/>
					</ReferenceInput>
					<NumberInput
						min='0'
						onInput={inputNumber}
						source='amount'
						label='补积分'
						isRequired
					/>
					<TextInput
						source='comment'
						label='备注'
						rowsMax={15}
						multiline
						fullWidth
						isRequired
					/>
					<ImageInput source='imagee' label='凭证' accept='image/*' isRequired>
						<ImageField source='src' title='title' />
					</ImageInput>
				</SimpleForm>
			</Edit>
		</Root>
	);
};

const PointDecrement = ({ onClose, type, ...props }) => {
	if (!props.id) {
		return null;
	}
	return (
		<Root>
			<Title>
				<Typography variant='h6'>清除积分</Typography>
				<IconButton onClick={onClose}>
					<CloseIcon />
				</IconButton>
			</Title>

			<Edit {...props} title='清除积分'>
				<SimpleForm
					className={Form}
					toolbar={<PointDelToolbar onClose={onClose} recordId={props.id} />}
				>
					<NumberInput
						min='0'
						onInput={inputNumber}
						source='amount'
						label='清除积分'
						isRequired
					/>
					<TextInput
						source='comment'
						label='备注'
						maxRows={15}
						multiline
						fullWidth
						isRequired
					/>
				</SimpleForm>
			</Edit>
		</Root>
	);
};

const MemberEdit = ({ onClose, type, ...props }) => {
	if (!props.id) {
		return null;
	}
	return (
		<Root>
			<Title>
				<Typography variant='h6'>会员信息</Typography>
				<IconButton onClick={onClose}>
					<CloseIcon />
				</IconButton>
			</Title>
			<Edit {...props} title='编辑会员'>
				<SimpleForm className={Form}>
					<TextInput source='name' label='名称' />
					<TextInput source='birthday' label='生日' />
					<SelectInput
						source='gender'
						optionText='name'
						optionValue='id'
						label='性别'
						choices={genderTypes}
					/>
				</SimpleForm>
			</Edit>
		</Root>
	);
};

export default PointEdit;
