import { useMediaQuery } from "@mui/material";
import {
  ChipField,
  Datagrid,
  DateField,
  Filter,
  List,
  NumberField,
  ReferenceField,
  SimpleList,
  TextField,
  TextInput,
} from "react-admin";
import { Pagination as Ppagination } from "../components";
const MemberGiftOrdersList = (props) => {
  const isSmall = useMediaQuery("(max-width:600px)");
  const OrderFilter = (props) => (
    <Filter {...props}>
      <TextInput label="搜索" source="mobile" />
    </Filter>
  );
  return (
    <List
      title="礼品订单"
      {...props}
      pagination={<Ppagination />}
      filters={<OrderFilter />}
    >
      {isSmall ? (
        <SimpleList
          primaryText={(record) => record.order_no}
          secondaryText={(record) => (
            <span>兑换时间 {record.exchanged_at}</span>
          )}
          tertiaryText={(record) => <span>核销时间 {record.audited_at}</span>}
        />
      ) : (
        <Datagrid>
          <TextField label="订单编号" source="order_no" />
          <ReferenceField
            label="会员姓名"
            source="member_id"
            reference="members"
          >
            <TextField source="name" />
          </ReferenceField>
          <ReferenceField
            label="会员电话"
            source="member_id"
            reference="members"
          >
            <ChipField source="mobile" />
          </ReferenceField>

          <ReferenceField
            label="礼品"
            source="member_gift_id"
            reference="member/gifts"
          >
            <ChipField source="name.zh" />
          </ReferenceField>
          <NumberField label="消耗积分" source="exchanged_points" />
          <DateField
            label="兑换时间"
            source="exchanged_at"
            showTime
            locales="zh-CN"
          />
          <TextField label="核销人" source="auditor.data.name" />
          <DateField
            source="audited_at"
            showTime
            label="核销时间"
            locales="zh-CN"
          />
        </Datagrid>
      )}
    </List>
  );
};

export default MemberGiftOrdersList;
