import { Admin, defaultTheme, CustomRoutes, Resource } from "react-admin";

import { ActivitiesCreate, ActivitiesEdit, ActivitiesList } from "./Activities";
import {
  AdvertisementsCreate,
  AdvertisementsEdit,
  AdvertisementsList,
} from "./ads";
import { AuditorsCreate, AuditorsEdit, AuditorsList } from "./Auditors";
import { CategoriesCreate, CategoriesEdit, CategoriesList } from "./shop/Categories";
import { IndustriesCreate, IndustriesEdit, IndustriesList } from "./shop/Industries";
import { CouponsCreate, CouponsEdit, CouponsList } from "./Coupons";
import Dashboard from "./Dashboard";
import { FloorsCreate, FloorsEdit, FloorsList } from "./Floors";
import MyLayout from "./Layout";
import {
  MemberGiftCreate,
  MemberGiftEdit,
  MemberGiftList,
  MemberGiftOrdersList,
} from "./member-gifts";
import {
  MemberLevelsCreate,
  MemberLevelsEdit,
  MemberLevelsList,
} from "./MemberLevels";
import { MembersCreate, MembersList } from "./Members";
import { MemberBirthdayList } from "./MembersBirthday";
import {
  ParkingCouponsCreate,
  ParkingCouponsEdit,
  ParkingCouponsList,
  ParkingOrdersList,
} from "./ParkingCoupons";
import { PointsHistoryList } from "./PointsHistory";
import { RolesCreate, RolesEdit, RolesList } from "./Roles";
import { ShopsCreate, ShopsEdit, ShopsList } from "./Shops";
import {
  SupermarketShowCreate,
  SupermarketShowEdit,
  SupermarketShowList,
} from "./SupermarketCouponsShow";
import { UsersCreate, UsersEdit, UsersList } from "./Users";
import authProvider from "./auth";
import dataProvider from "./http";
import i18nProvider from "./i18n";
import { deepmerge } from '@mui/utils';
import { Route } from "react-router-dom";
import { ArticleCreate, ArticleEdit, ArticleList } from "./Article";
import { ExportReportList } from "./ExportReport";
import Login from "./Login";
import { MemberBirthdayWeekList } from "./MemberBirthdayWeek";
import MemberRights from "./MemberRights";
import MembersChart from "./members/MembersChart";
import MembersLevels from "./MembersLevels";
import MembersPoints from "./MembersPoints";
import MembersUsage from "./MembersUsage";
import { PointsUseList } from "./PointsHistoryUse";
import {
  RegisterPointsCreate,
  RegisterPointsEdit,
  RegisterPointsList,
} from "./RegisterPoints";
import { ReportList } from "./Report";
import { ShopQrcodeList } from "./ShopQrcode";
import { WeappList } from "./Weapp";
import { SurveyList } from './survey/pages/SurveyList';
import { SurveyCreate } from './survey/pages/SurveyCreate';
import { SurveyEdit } from './survey/pages/SurveyEdit';
import { SurveyShow } from './survey/pages/SurveyShow';
import NotFound from "./common-pages/NotFound";
import { SurveyStats } from "./survey/pages/SurveyStats";
import SurveyQuestionStats from "./survey/pages/SurveyQuestionStats";

const theme = deepmerge(defaultTheme, {
  components: {
    MuiFormControl: { defaultProps: { fullWidth: undefined } },
    MuiTextField: { defaultProps: { fullWidth: undefined } },
    MuiAutocomplete: { defaultProps: { fullWidth: undefined } },
    RaSimpleFormIterator: { defaultProps: { fullWidth: undefined } },
    RaTranslatableInputs: { defaultProps: { fullWidth: undefined } },
  }
});

const App = () => {
  return (
    <Admin
      catchAll={NotFound}
      title=""
      dataProvider={dataProvider}
      authProvider={authProvider}
      i18nProvider={i18nProvider}
      loginPage={Login}
      dashboard={Dashboard}
      layout={MyLayout}
      disableTelemetry
      theme={theme}
    >
      <CustomRoutes>
        <Route path="/members/:id" />
        <Route path="/weapp" element={<WeappList />} />
        <Route path="/birthday/week" element={<MemberBirthdayWeekList />} />
        <Route path="/export" element={<ExportReportList />} />
        <Route path="/ponit/use" element={<PointsUseList />} />
        <Route path="/report" element={<ReportList />} />
        <Route path="/member/rights" element={<MemberRights />} />
        <Route path="/data/member/growth" element={<MembersChart />} />
        <Route
          path="/data/member/point/increment"
          element={<MembersPoints />}
        />
        <Route path="/data/member/point/usage" element={<MembersUsage />} />
        <Route path="/data/member/levels" element={<MembersLevels />} />
        <Route path="/data/member/info" element={<Dashboard />} />
        <Route path="/data/survey/:id/stats" element={<SurveyStats />} />
        <Route path="/data/survey/:id/results" element={<SurveyQuestionStats />} />
      </CustomRoutes>
      <Resource
        name="members"
        list={MembersList}
        create={MembersCreate}
        options={{ label: "会员" }}
      />
      <Resource
        name="member/levels"
        list={MemberLevelsList}
        create={MemberLevelsCreate}
        edit={MemberLevelsEdit}
        options={{ label: "会员等级" }}
      />
      <Resource
        name="member/gifts"
        list={MemberGiftList}
        create={MemberGiftCreate}
        edit={MemberGiftEdit}
        options={{ label: "会员礼品" }}
      />
      <Resource
        name="member/gift/orders"
        list={MemberGiftOrdersList}
        options={{ label: "礼品订单" }}
      />
      <Resource
        name="shops"
        list={ShopsList}
        edit={ShopsEdit}
        create={ShopsCreate}
        options={{ label: "店铺" }}
      />
      <Resource
        name="floors"
        list={FloorsList}
        edit={FloorsEdit}
        create={FloorsCreate}
        options={{ label: "楼层" }}
      />
      <Resource
        name="shop/industries"
        list={IndustriesList}
        edit={IndustriesEdit}
        create={IndustriesCreate}
        options={{ label: "业态" }}
      />
      <Resource
        name="shop/categories"
        list={CategoriesList}
        edit={CategoriesEdit}
        create={CategoriesCreate}
        options={{ label: "分类" }}
      />
      <Resource
        name="advertisements"
        list={AdvertisementsList}
        edit={AdvertisementsEdit}
        create={AdvertisementsCreate}
        options={{ label: "广告" }}
      />
      <Resource
        name="articles"
        list={ArticleList}
        edit={ArticleEdit}
        create={ArticleCreate}
        options={{ label: "文章" }}
      />
      <Resource
        name="supermarket-coupons"
        list={CouponsList}
        edit={CouponsEdit}
        create={CouponsCreate}
        options={{ label: "超市优惠券" }}
      />
      <Resource
        name="coupons"
        list={CouponsList}
        edit={CouponsEdit}
        create={CouponsCreate}
        options={{ label: "商铺优惠券" }}
      />
      <Resource
        name="parking/coupons"
        list={ParkingCouponsList}
        edit={ParkingCouponsEdit}
        create={ParkingCouponsCreate}
        options={{ label: "停车优惠券" }}
      />
      <Resource
        name="parking/orders"
        list={ParkingOrdersList}
        options={{ label: "停车订单" }}
      />
      <Resource
        name="admins"
        list={UsersList}
        edit={UsersEdit}
        create={UsersCreate}
        options={{ label: "管理员" }}
      />
      <Resource
        name="roles"
        list={RolesList}
        edit={RolesEdit}
        create={RolesCreate}
        options={{ label: "角色" }}
      />
      <Resource
        name="auditors"
        list={AuditorsList}
        edit={AuditorsEdit}
        create={AuditorsCreate}
        options={{ label: "核销员" }}
      />
      <Resource
        name="ponit/histories"
        list={PointsHistoryList}
        options={{ label: "会员获取积分明细" }}
      />
      <Resource
        name="member/birthdays"
        list={MemberBirthdayList}
        options={{ label: "会员今日生日" }}
      />
      <Resource
        name="point/activities"
        list={ActivitiesList}
        edit={ActivitiesEdit}
        create={ActivitiesCreate}
        options={{ label: "积分活动管理" }}
      />
      <Resource
        name="member/extra/infos"
        list={RegisterPointsList}
        edit={RegisterPointsEdit}
        create={RegisterPointsCreate}
        options={{ label: "注册积分" }}
      />
      <Resource
        name="supermarket/show"
        list={SupermarketShowList}
        edit={SupermarketShowEdit}
        create={SupermarketShowCreate}
        options={{ label: "子超市优惠券" }}
      />
      <Resource
        name="invitations"
        list={ShopQrcodeList}
        options={{ label: "店铺专属二维码统计" }}
      />
      <Resource name="permissions" />
      <Resource name="users" />
      <Resource name="settings" />
      <Resource
        name="surveys"
        list={SurveyList}
        create={SurveyCreate}
        edit={SurveyEdit}
        show={SurveyShow}
        options={{ label: "问卷" }}
      />
      
    </Admin>
  );
};

export default App;
