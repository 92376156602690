import {
  Box,
  Card,
  CardContent,
  Grid,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import dayjs from "dayjs";
import { useState } from "react";
import { Loading, useGetList, useGetOne } from "react-admin";
import { useParams } from "react-router-dom";
import {
  Bar,
  BarChart,
  CartesianGrid,
  Cell,
  Legend,
  Pie,
  PieChart,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";

const COLORS = ["#0088FE", "#00C49F", "#FFBB28", "#FF8042", "#8884d8"];

export const SurveyQuestionStats = () => {
  const { id } = useParams();
  const [viewMode, setViewMode] = useState("charts"); // 'charts' or 'table'
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const { data: survey, isLoading: surveyLoading } = useGetOne("surveys", {
    id,
  });
  const { data: answers, isLoading: answersLoading } = useGetList(
    `surveys/${id}/answers`,
    {
      filter: { survey_id: id },
      pagination: { page: 1, perPage: 1000 },
    },
  );

  if (surveyLoading || answersLoading) return <Loading />;

  // Transform answers into table format
  const tableData = answers.reduce((acc, answer) => {
    if (!acc[answer.response_id]) {
      acc[answer.response_id] = {
        id: answer.response_id,
        created_at: dayjs(answer.created_at).format("YYYY-MM-DD HH:mm:ss"),
      };
    }
    
    // Add answer to the appropriate question column
    const question = survey.questions?.data.find(
        (q) => {
            return q.id === answer.question_id
        }
    );
    if (question) {
      let value = "";
      switch (question.type) {
        case 1: // Single choice
        case 2: // Multiple choice
          const optionIds = answer.option_ids || "[]";
          value = optionIds
            .map(
              (optionId) =>
                question.options?.data.find((opt) => opt.id === optionId)
                  ?.content.zh,
            )
            .filter(Boolean)
            .join(", ");
          break;
        case 3: // Text
          value = answer.text_answer;
          break;
        case 4: // Rating
          value = answer.rating?.toString() || "";
          break;
      }
      acc[answer.response_id][`question_${question.id}`] = value;
    }
    return acc;
  }, {});
  console.log("🚀 ~ tableData ~ tableData:", tableData);
  const handleQuestionChange = (event, newValue) => {
    setCurrentQuestion(newValue);
  };

  return (
    <Box>
      <Card>
        <CardContent>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            mb={2}
          >
            <Typography variant="h5">问题统计分析</Typography>
            <Tabs
              value={viewMode}
              onChange={(e, newValue) => setViewMode(newValue)}
            >
              <Tab label="图表视图" value="charts" />
              <Tab label="表格视图" value="table" />
            </Tabs>
          </Box>

          {viewMode === "charts" ? (
            <>
              <Tabs
                value={currentQuestion}
                onChange={handleQuestionChange}
                variant="scrollable"
                scrollButtons="auto"
              >
                {survey.questions?.data.map((question, index) => (
                  <Tab
                    key={question.id}
                    label={`${question.content.zh}`}
                    value={index}
                  />
                ))}
              </Tabs>

              <QuestionStats
                question={survey.questions?.data[currentQuestion]}
                answers={answers.filter(
                  (a) =>
                    a.question_id ===
                    survey.questions?.data[currentQuestion].id,
                )}
              />
            </>
          ) : (
            <Box sx={{ width: "100%", overflowX: "auto" }}>
              <DataGrid
                rows={Object.values(tableData)}
                columns={
                  [
                    {
                        field: 'created_at',
                        headerName: '提交时间',
                        width: 180,
                        valueFormatter: (params) =>
                            new Date(params.value).toLocaleString(),
                    },
                    ...survey.questions?.data.map(question => ({
                        field: `question_${question.id}`,
                        headerName: question.content.zh,
                        width: 200,
                        sortable: true,
                    }))
                  ]
                }
                autoHeight
                pageSize={10}
                rowsPerPageOptions={[10, 25, 50, 100]}
                disableSelectionOnClick
              />
            </Box>
          )}
        </CardContent>
      </Card>
    </Box>
  );
};

const QuestionStats = ({ question, answers }) => {
  const totalResponses = answers.length;

  switch (question.type) {
    case 1: // Single choice
    case 2: // Multiple choice
      return (
        <ChoiceQuestionStats
          question={question}
          answers={answers}
          totalResponses={totalResponses}
        />
      );
    case 3: // Text
      return (
        <TextQuestionStats
          question={question}
          answers={answers}
          totalResponses={totalResponses}
        />
      );
    case 4: // Rating
      return (
        <RatingQuestionStats
          question={question}
          answers={answers}
          totalResponses={totalResponses}
        />
      );
    default:
      return null;
  }
};

const ChoiceQuestionStats = ({ question, answers, totalResponses }) => {
  // Process option statistics
  const optionStats = question.options?.data.map((option) => {
    const count = answers.filter((answer) =>
      answer.option_ids.includes(option.id),
    ).length;

    return {
      name: option.content.zh,
      count,
      percentage: ((count / totalResponses) * 100).toFixed(1),
    };
  });

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={6}>
        <Typography variant="h6" gutterBottom>
          {question.content.zh}
        </Typography>
        <Typography color="textSecondary" gutterBottom>
          总回答数: {totalResponses}
        </Typography>

        <PieChart width={400} height={300}>
          <Pie
            data={optionStats}
            dataKey="count"
            nameKey="name"
            cx="50%"
            cy="50%"
            outerRadius={100}
            label={({ name, percentage }) => `${name}: ${percentage}%`}
          >
            {optionStats.map((entry, index) => (
              <Cell key={index} fill={COLORS[index % COLORS.length]} />
            ))}
          </Pie>
          <Tooltip />
          <Legend />
        </PieChart>
      </Grid>

      <Grid item xs={12} md={6}>
        <BarChart width={400} height={300} data={optionStats}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="name" />
          <YAxis />
          <Tooltip />
          <Bar dataKey="count" fill="#8884d8" />
        </BarChart>
      </Grid>
    </Grid>
  );
};

const TextQuestionStats = ({ question, answers, totalResponses }) => {
  // Get most common answers
  const answerFrequency = answers.reduce((acc, answer) => {
    const text = answer.text_answer;
    acc[text] = (acc[text] || 0) + 1;
    return acc;
  }, {});

  const topAnswers = Object.entries(answerFrequency)
    .sort(([, a], [, b]) => b - a)
    .slice(0, 10);

  return (
    <Box>
      <Typography variant="h6" gutterBottom>
        {question.content.zh}
      </Typography>
      <Typography color="textSecondary" gutterBottom>
        总回答数: {totalResponses}
      </Typography>

      <Typography variant="subtitle1" gutterBottom>
        常见回答:
      </Typography>
      {topAnswers.map(([text, count], index) => (
        <Box key={index} mb={1}>
          <Typography>
            {text}: {count} 次 ({((count / totalResponses) * 100).toFixed(1)}%)
          </Typography>
        </Box>
      ))}
    </Box>
  );
};

const RatingQuestionStats = ({ question, answers, totalResponses }) => {
  // Calculate rating distribution
  const ratingDistribution = Array(5).fill(0);
  answers.forEach((answer) => {
    if (answer.rating) {
      ratingDistribution[answer.rating - 1]++;
    }
  });

  const ratingData = ratingDistribution.map((count, index) => ({
    rating: index + 1,
    count,
    percentage: ((count / totalResponses) * 100).toFixed(1),
  }));

  const averageRating =
    answers.reduce((acc, answer) => acc + (answer.rating || 0), 0) /
    totalResponses;

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography variant="h6" gutterBottom>
          {question.content.zh}
        </Typography>
        <Typography color="textSecondary" gutterBottom>
          总回答数: {totalResponses}
        </Typography>
        <Typography variant="h4" gutterBottom>
          平均评分: {averageRating.toFixed(1)}
        </Typography>
      </Grid>

      <Grid item xs={12}>
        <BarChart width={600} height={300} data={ratingData}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="rating" />
          <YAxis />
          <Tooltip />
          <Bar dataKey="count" fill="#8884d8">
            {ratingData.map((entry, index) => (
              <Cell key={index} fill={COLORS[index % COLORS.length]} />
            ))}
          </Bar>
        </BarChart>
      </Grid>
    </Grid>
  );
};

export default SurveyQuestionStats;
