import {
  AutocompleteInput,
  Edit,
  ImageField,
  ImageInput,
  ReferenceInput,
  SelectInput,
  SimpleForm,
  useNotify,
  useRedirect,
  useUpdate,
} from "react-admin";
import { CreateToolbar, TopBarActions } from "../components";
import { imageInputFormat } from "../constants";
import {
  adTypes,
  adUrls,
  filterOptionsQuery,
  filterToQuery,
  optionText,
  surveyOptionText,
} from "./helpers";

export const AdvertisementsEdit = (props) => {
  const [update] = useUpdate();
  const notify = useNotify();
  const redirect = useRedirect();

  const handleSubmit = (values) => {
    const transformedValues = {
      article: values.article_id,
      survey_id: values.survey_id,
      type: values.type,
      url: values.url,
      start_at: values.start_at,
      end_at: values.end_at,
      image: values.image,
    };
    console.log("🚀 ~ handleSubmit ~ values:", transformedValues);
    return update(
      "advertisements",
      { id: values.id, data: transformedValues },
      {
        onSuccess: () => {
          notify("编辑成功", { type: "success" });
          redirect("list", "advertisements");
        },
        onError: (error) => {
          console.log(
            "🚀 ~ handleSubmit ~ error:",
            error,
            error.errors,
            error.message,
          );
          if (error.status === 422) {
            notify("表单验证失败: " + (error.message || "请检查输入"), {
              type: "warning",
            });
          } else {
            notify("编辑失败: " + (error.message || "未知错误"), {
              type: "error",
            });
          }
        },
      },
    );
  };
  return (
    <Edit {...props} title="编辑广告" actions={<TopBarActions />}>
      <SimpleForm toolbar={<CreateToolbar />} onSubmit={handleSubmit}>
        <SelectInput
          source="type"
          optionText="name"
          optionValue="id"
          label="广告类型"
          choices={adTypes}
        />
        <SelectInput
          source="url"
          optionText="name"
          optionValue="id"
          label="连接页面"
          choices={adUrls}
        />
        <ReferenceInput reference="articles" source="article_id" label="文章">
          <AutocompleteInput
            filterToQuery={filterToQuery}
            optionText={optionText}
            optionValue="id"
          />
        </ReferenceInput>
        <ReferenceInput reference="surveys" source="survey_id" label="调查问卷">
          <AutocompleteInput
            filterToQuery={filterOptionsQuery}
            optionText={surveyOptionText}
            optionValue="id"
          />
        </ReferenceInput>
        <ImageInput
          source="image.zh"
          label="中文图片"
          accept="image/*"
          format={imageInputFormat}
        >
          <ImageField source="src" />
        </ImageInput>
        <ImageInput
          source="image.ug"
          label="维文图片"
          accept="image/*"
          format={imageInputFormat}
        >
          <ImageField source="src" />
        </ImageInput>
      </SimpleForm>
    </Edit>
  );
};

export default AdvertisementsEdit;