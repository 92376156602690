import {
    Create,
    SimpleForm,
    TextInput,
    DateInput,
    ArrayInput,
    SimpleFormIterator,
    SelectInput,
    NumberInput,
    required,
    useNotify,
    useRedirect,
    useCreate,
} from 'react-admin';
import { CreateToolbar } from '../../components';
import { Card, CardContent, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useState } from 'react';
import { httpClient } from '../../http';

const StyledQuestionCard = styled(Card)(({ theme }) => ({
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
}));

const StyledOptionsContainer = styled('div')(({ theme }) => ({
    marginLeft: theme.spacing(2),
}));

const QuestionTypeInput = ({ source, onChange }) => (
    <SelectInput
        source={source}
        label="问题类型"
        validate={required()}
        onChange={onChange}
        choices={[
            { id: 1, name: '单选题' },
            { id: 2, name: '多选题' },
            { id: 3, name: '文本题' },
            { id: 4, name: '评分题' },
        ]}
    />
);

const validateSurveyCreation = (values) => {
    console.log("🚀 ~ validateSurveyCreation ~ values:", values)
    const errors = {};
    if (!values.name?.zh || !values.name?.ug) {
        errors.name = '请填写调查名称（中文和维文）';
    }
    if (!values.points) {
        errors.points = '请填写完成积分';
    }
    if (!values.questions || values.questions.length === 0) {
        errors.questions = '至少需要一个问题';
    }
    return errors;
};

export const SurveyCreate = (props) => {
    const [questionTypes, setQuestionTypes] = useState({});
    const notify = useNotify();
    const redirect = useRedirect();
    const [create] = useCreate();

    const handleSubmit = (values) => {
        const transformedValues = {
            ...values,
            questions: values.questions?.map((question, index) => ({
                ...question,
                order: question.order || index + 1,
                options: question.options?.map((option, optIndex) => ({
                    ...option,
                    order: option.order || optIndex + 1
                }))
            }))
        };

        // Use create with proper parameters and handle both success and error cases
        return create(
            'surveys',
            { data: transformedValues },
            {
                onSuccess: () => {
                    notify('问卷创建成功', { type: 'success' });
                    redirect('list', 'surveys');
                },
                onError: (error) => {
                    console.log("🚀 ~ handleSubmit ~ error:", error, error.errors, error.message)
                    if (error.status === 422) {
                        notify('表单验证失败: ' + (error.message || '请检查输入'), { type: 'warning' });
                    } else {
                        notify('创建失败: ' + (error.message || '未知错误'), { type: 'error' });
                    }
                }
            }
        );
    };

    const handleQuestionTypeChange = (questionIndex, type) => {
        setQuestionTypes(prev => ({
            ...prev,
            [questionIndex]: type
        }));
    };

    return (
        <Create {...props} title="创建问卷调查">
            <SimpleForm
                toolbar={<CreateToolbar />}
                validate={validateSurveyCreation}
                onSubmit={handleSubmit}
                mode="onSubmit"
                reValidateMode="onSubmit"
                defaultValues={{
                    status: 0, // 默认为草稿状态
                    questions: []
                }}
            >
                <Card className={StyledQuestionCard}>
                    <CardContent>
                        <Typography variant="h6">基本信息</Typography>
                        <TextInput source="name.zh" label="调查名称(中文)" validate={required()} />
                        <TextInput source="name.ug" label="调查名称(维文)" validate={required()} />
                        <NumberInput source="points" label="完成积分" validate={required()} min={0} />
                        <SelectInput
                            source="status"
                            label="状态"
                            choices={[
                                { id: 0, name: '草稿' },
                                { id: 1, name: '已发布' },
                                { id: 2, name: '已关闭' },
                            ]}
                        />
                        <DateInput source="start_date" label="开始日期" />
                        <DateInput source="end_date" label="结束日期" />
                    </CardContent>
                </Card>

                <ArrayInput source="questions" label="问题列表">
                    <SimpleFormIterator inline>

                        <NumberInput
                            source="order"
                            label="问题序号"
                            validate={required()}
                        />
                        <TextInput
                            source="content.zh"
                            label="问题内容(中文)"
                            validate={required()}
                        />
                        <TextInput
                            source="content.ug"
                            label="问题内容(维文)"
                            validate={required()}
                        />
                        <QuestionTypeInput
                            source="type"
                            onChange={(e) => {
                                const index = e.target.name.match(/questions\.(\d+)\.type/)[1];
                                handleQuestionTypeChange(parseInt(index), parseInt(e.target.value));
                            }}
                        />

                        <ArrayInput source="options" label="选项列表">
                            <SimpleFormIterator inline>
                                <NumberInput
                                    source="order"
                                    label="选项序号"
                                    validate={required()}
                                />
                                <TextInput
                                    source="content.zh"
                                    label="选项内容(中文)"
                                    validate={required()}
                                />
                                <TextInput
                                    source="content.ug"
                                    label="选项内容(维文)"
                                    validate={required()}
                                />

                            </SimpleFormIterator>
                        </ArrayInput>

                    </SimpleFormIterator>
                </ArrayInput>
            </SimpleForm>
        </Create>
    );
};

export default SurveyCreate; 