import * as React from "react";
import { Layout } from "react-admin";
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import MyMenu from "./MyMenu";
import { MyAppBar } from "./common-pages/MyAppBar";


const MyLayout = ({ children }) => {
    return <Layout menu={MyMenu} appBar={MyAppBar} appBarAlwaysOn>
        {children}
        <ReactQueryDevtools />
    </Layout>
};

export default MyLayout;
