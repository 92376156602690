import {
	List,
	Datagrid,
	Edit,
	SimpleForm,
	ReferenceInput,
	SelectInput,
	TextInput,
	TextField,
	Filter,
	SimpleList,
	DeleteButton,
	Create,
	AutocompleteInput,
	EditButton,
	ArrayField,
	SingleFieldList,
	ChipField,
	CheckboxGroupInput,
	ReferenceArrayInput,
} from 'react-admin';
import { useMediaQuery } from '@mui/material';
import { CreateToolbar, FullNameField, TopBarActions } from './components';

const UsersFilter = (props) => (
	<Filter {...props}>
		<TextInput label='搜索' source='name' />
		<ReferenceInput label='角色' source='role' reference='users' allowEmpty>
			<SelectInput optionText='role' />
		</ReferenceInput>
	</Filter>
);

export const UsersList = (props) => {
	const isSmall = useMediaQuery('(max-width:600px)');
	return (
		<List {...props} filters={<UsersFilter />} title='管理员管理'>
			{isSmall ? (
				<SimpleList
					primaryText={(record) => record.nickname}
					tertiaryText={(record) => record.mobile}
				/>
			) : (
				<Datagrid>
					<FullNameField />
					<TextField source='name' label='管理员姓名' />
					<TextField source='mobile' label='电话号码' />
					<ArrayField source='roles.data' label='管理者角色'>
						<SingleFieldList>
							<ChipField source='name' />
						</SingleFieldList>
					</ArrayField>
					<EditButton label='编辑' />
					<DeleteButton
						label='删除'
						mutationMode='pessimistic'
						confirmTitle=''
					/>
				</Datagrid>
			)}
		</List>
	);
};

const UsersTitle = ({ record }) => {
	return <span>用户名 {record && record.name ? `"${record.name}"` : ''}</span>;
};

const filterToUser = (searchText) => ({ mobile: searchText });

const mobileMatch = (filter, choice) =>
	choice &&
	typeof choice.mobile === 'string' &&
	choice.mobile.includes(`${filter}`);

const optionText = (record) => `${record?.name} ${record?.mobile}`;

export const UsersEdit = (props) => (
	<Edit title={<UsersTitle />} actions={<TopBarActions />} {...props}>
		<SimpleForm>
			<ReferenceArrayInput
				source='roles.data'
				reference='roles'
				label='角色'
				fullWidth
				format={(value) => value.map((i) => i.id ?? i)}
			>
				<CheckboxGroupInput optionText='name' optionValue='id' />
			</ReferenceArrayInput>
		</SimpleForm>
	</Edit>
);

export const UsersCreate = (props) => (
	<Create title={<UsersTitle />} actions={<TopBarActions />} {...props}>
		<SimpleForm toolbar={<CreateToolbar />}>
			<ReferenceInput reference='users' source='user_id' label='用户'>
				<AutocompleteInput
					filterToQuery={filterToUser}
					optionText={optionText}
					optionValue='id'
					matchSuggestion={mobileMatch}
				/>
			</ReferenceInput>
			<ReferenceArrayInput source='role_id' reference='roles' label='角色'>
				<CheckboxGroupInput optionText='name' optionValue='id' />
			</ReferenceArrayInput>
		</SimpleForm>
	</Create>
);
