import {
  Edit,
  SimpleForm,
  TextInput,
  DateInput,
  ArrayInput,
  SimpleFormIterator,
  SelectInput,
  NumberInput,
  required,
  useUpdate,
  useNotify,
  useRedirect,
} from 'react-admin';
import { Card, CardContent, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

const StyledQuestionCard = styled(Card)(({ theme }) => ({
  marginTop: theme.spacing(2),
  marginBottom: theme.spacing(2),
}));

const StyledOptionsContainer = styled('div')(({ theme }) => ({
  marginLeft: theme.spacing(2),
}));

const QuestionTypeInput = () => (
  <SelectInput
    source="type"
    label="问题类型"
    validate={required()}
    choices={[
      { id: 1, name: '单选题' },
      { id: 2, name: '多选题' },
      { id: 3, name: '文本题' },
    //   { id: 4, name: '评分题' },
    ]}
  />
);
export const SurveyEdit = () => {
  const [update] = useUpdate();
  const notify = useNotify();
  const redirect = useRedirect();
  const handleSubmit = (values) => {
    console.log("🚀 ~ handleSubmit ~ values:", values)
    const transformedValues = {
        ...values,
        questions: values.questions?.data.map((question, index) => ({
            ...question,
            order:  index + 1,
            options: question.options?.data.map((option, optIndex) => ({
              ...option,
              order:  optIndex + 1
            }))
        }))
    };
  
    return update(
        'surveys',
        { id: values.id, data: transformedValues },
        {
            onSuccess: () => {
                notify('问卷编辑成功', { type: 'success' });
                redirect('list', 'surveys');
            },
            onError: (error) => {
                console.log("🚀 ~ handleSubmit ~ error:", error, error.errors, error.message)
                if (error.status === 422) {
                    notify('表单验证失败: ' + (error.message || '请检查输入'), { type: 'warning' });
                } else {
                    notify('编辑失败: ' + (error.message || '未知错误'), { type: 'error' });
                }
            }
        }
    );
  };
  return (
    <Edit title="编辑问卷调查">
      <SimpleForm mode="onSubmit" onSubmit={handleSubmit}>
        <StyledQuestionCard>
          <CardContent>
            <Typography variant="h6">基本信息</Typography>
            <TextInput source="name.zh" label="调查名称(中文)" validate={required()} />
            <TextInput source="name.ug" label="调查名称(维文)" validate={required()} />
            <NumberInput source="points" label="完成积分" validate={required()} min={0} />
            <SelectInput
              source="status"
              label="状态"
              choices={[
                { id: 0, name: '草稿' },
                { id: 1, name: '已发布' },
                { id: 2, name: '已关闭' },
              ]}
            />
            <DateInput source="start_date" label="开始日期" />
            <DateInput source="end_date" label="结束日期" />
          </CardContent>
        </StyledQuestionCard>

        <ArrayInput source="questions.data" label="问题列表">
          <SimpleFormIterator>
            <StyledQuestionCard>
              <CardContent>
                <TextInput source="content.zh" label="问题内容(中文)" validate={required()} />
                <TextInput source="content.ug" label="问题内容(维文)" validate={required()} />
                <QuestionTypeInput />
                
                <ArrayInput source="options.data" label="选项">
                  <SimpleFormIterator>
                    <StyledOptionsContainer>
                      <TextInput source="content.zh" label="选项内容(中文)" validate={required()} />
                      <TextInput source="content.ug" label="选项内容(维文)" validate={required()} />
                    </StyledOptionsContainer>
                  </SimpleFormIterator>
                </ArrayInput>
              </CardContent>
            </StyledQuestionCard>
          </SimpleFormIterator>
        </ArrayInput>
      </SimpleForm>
    </Edit>
  );
};

export default SurveyEdit; 