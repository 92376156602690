import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import StopIcon from "@mui/icons-material/Stop";
import { Box, Chip, Tooltip } from "@mui/material";
import {
  Button,
  Datagrid,
  DateField,
  DeleteButton,
  EditButton,
  FunctionField,
  List,
  NumberField,
  SelectField,
  ShowButton,
  useNotify,
  useRecordContext,
  useUpdate,
} from "react-admin";
import { Link } from "react-router-dom";

const SurveyStatusField = ({ record }) => {
  const statusMap = {
    0: { label: "草稿", color: "default" },
    1: { label: "已发布", color: "primary" },
    2: { label: "已关闭", color: "error" },
  };

  const status = statusMap[record?.status] || statusMap[0];
  return <Chip label={status.label} color={status.color} size="small" />;
};

const statusChoices = [
  { id: 0, name: "草稿" },
  { id: 1, name: "已发布" },
  { id: 2, name: "已关闭" },
];

const StatusActions = () => {
  const [update] = useUpdate();
  const notify = useNotify();
  const record = useRecordContext();

  const handleStatusChange = (newStatus) => {
    if (!record?.id) {
      notify("无法更新：记录ID不存在", { type: "error" });
      return;
    }

    update(
      "surveys",
      {
        id: record.id,
        data: { status: newStatus }, // Wrap status in a data object
      },
      {
        method: "PATCH",
        path: `/surveys/${record.id}/status`,
        onSuccess: () => {
          const statusText = statusChoices.find(
            (s) => s.id === newStatus,
          )?.name;
          notify(`问卷状态已更新为${statusText}`, { type: "success" });
        },
        onError: (error) => {
          notify(`更新失败: ${error.message}`, { type: "error" });
        },
      },
    );
  };

  return (
    <Box display="flex" gap={1}>
      {record?.status == 1 ? (
        <Tooltip title="关闭">
          <Button
            size="small"
            color="error"
            onClick={() => handleStatusChange(2)}
          >
            关闭
            <StopIcon />
          </Button>
        </Tooltip>
      ) : (
        <Button
          size="small"
          color="primary"
          onClick={() => handleStatusChange(1)}
        >
          发布
          <PlayArrowIcon />
        </Button>
      )}
    </Box>
  );
};
const ResultActions = () => {
  const [update] = useUpdate();
  const notify = useNotify();
  const record = useRecordContext();

  return (
    <Box display="flex" gap={1} style={{ marginLeft: "auto", fontSize: "12px" }}>
      <Link to={`/data/survey/${record?.id}/stats`}>问卷统计概览</Link>
      <Link to={`/data/survey/${record?.id}/results`}>问题统计分析</Link>
    </Box>
  );
};
export const SurveyList = () => (
  <List
    title="问卷调查列表"
    sort={{ field: "created_at", order: "DESC" }}
    perPage={10}
  >
    <Datagrid rowClick={false}>
      <FunctionField label="调查名称" render={(record) => record?.name.zh} />
      <FunctionField
        label="调查名称(维文)"
        render={(record) => record?.name.ug}
      />
      <NumberField source="points" label="积分" />
      <SelectField source="status" label="状态" choices={statusChoices} />
      <DateField source="start_date" label="开始日期" />
      <DateField source="end_date" label="结束日期" />
      <ShowButton />
      <EditButton />
      <DeleteButton />
      <StatusActions />
      <ResultActions />
    </Datagrid>
  </List>
);

export default SurveyList;
