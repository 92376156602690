import * as React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import { Title } from "react-admin";

export default () => (
    <Card>
        <Title title="未找到此页面" />
        <CardContent>
            <h1>404: 页面不见了哦</h1>
        </CardContent>
    </Card>
);