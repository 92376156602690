import * as React from 'react';
import { useState } from 'react';
import PropTypes from 'prop-types';
import { yupResolver } from '@hookform/resolvers/yup';
import { useLocation } from 'react-router-dom';
import { styled, createTheme } from '@mui/material/styles';
import {
	Avatar,
	Button,
	Card,
	CardActions,
	CircularProgress,
	TextField,
	Container,
} from '@mui/material';
import LockIcon from '@mui/icons-material/Lock';
import {
	Notification,
	useTranslate,
	useLogin,
	useNotify,
	Form,
	Labeled,
	SimpleForm,
} from 'react-admin';
import * as yup from 'yup';
import { lightTheme } from './themes';
import Password from 'antd/es/input/Password';

// Replace makeStyles with styled components
const MainContainer = styled('div')(({ theme }) => ({
	display: 'flex',
	flexDirection: 'column',
	minHeight: '100vh',
	alignItems: 'center',
	justifyContent: 'flex-start',
	background:
		'url(https://pic1.zhimg.com/v2-65354520edd978c49d00a7a710feb9c5_r.jpg?source=1940ef5c)',
	backgroundRepeat: 'no-repeat',
	backgroundSize: 'cover',
}));

const StyledCard = styled(Card)({
	minWidth: 300,
	marginTop: '6em',
});

const AvatarWrapper = styled('div')({
	margin: '1em',
	display: 'flex',
	justifyContent: 'center',
});

const StyledAvatar = styled(Avatar)(({ theme }) => ({
	backgroundColor: theme.palette.secondary.main,
}));

const HintText = styled('div')(({ theme }) => ({
	marginTop: '1em',
	display: 'flex',
	justifyContent: 'center',
	color: theme.palette.grey[500],
}));

const FormWrapper = styled('div')({
	padding: '0 1em 1em 1em',
});

const InputWrapper = styled('div')({
	marginTop: '1em',
});

const StyledCardActions = styled(CardActions)({
	padding: '0 1em 1em 1em',
});

const Login = () => {
	const [loading, setLoading] = useState(false);
	const [username, setUsername] = useState('');
	const [password, setPassword] = useState('');
	const translate = useTranslate();
	const notify = useNotify();
	const login = useLogin();
	const location = useLocation();

	const handleSubmit = (auth) => {
		setLoading(true);
		login(
			{ username, password },
			location.state ? location.state.nextPathname : '/'
		).catch((error) => {
			setLoading(false);
			notify(
				typeof error === 'string'
					? error
					: typeof error === 'undefined' || !error.message
					? 'ra.auth.sign_in_error'
					: error.message,
				{ type: 'warning' }
			);
		});
	};
	// yup.setLocale({
	// 	mixed: {
	// 		default: translate('ra.validation.required'),
	// 	},
	// });
	const schema = yup
		.object()
		.shape({
			username: yup.string().required(),
			password: yup.string().required(),
		})
		.required();

	return (
		<MainContainer>
			<SimpleForm
				toolbar={false}
				onSubmit={handleSubmit}
				// resolver={yupResolver(schema)}
			>
				<StyledCard>
					<AvatarWrapper>
						<StyledAvatar>
							<LockIcon />
						</StyledAvatar>
					</AvatarWrapper>
					<HintText>管理员后台</HintText>
					<FormWrapper>
						<InputWrapper>
							<Labeled label={translate('ra.auth.username')}>
								<TextField
									autoFocus
									name='username'
									disabled={loading}
									onChange={(e) => setUsername(e.target.value)}
								/>
							</Labeled>
						</InputWrapper>
						<InputWrapper>
							<Labeled label={translate('ra.auth.password')}>
								<Password
									name='password'
									label={translate('ra.auth.password')}
									type='password'
									disabled={loading}
									onChange={(e) => setPassword(e.target.value)}
								/>
							</Labeled>
						</InputWrapper>
					</FormWrapper>
					<StyledCardActions>
						<Button
							variant='contained'
							type='submit'
							color='primary'
							disabled={loading}
							fullWidth
						>
							{loading && <CircularProgress size={25} thickness={2} />}
							{translate('ra.auth.sign_in')}
						</Button>
					</StyledCardActions>
				</StyledCard>
			</SimpleForm>
		</MainContainer>
	);
};

Login.propTypes = {
	authProvider: PropTypes.func,
	previousRoute: PropTypes.string,
};

const LoginWithTheme = (props) => (
	<Container theme={createTheme(lightTheme)}>
		<Login {...props} />
	</Container>
);

export default LoginWithTheme;
