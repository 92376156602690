import { makeStyles } from '@mui/styles';
const useStyles = makeStyles((theme) => ({

    ugTitle: {
        fontFamily: 'UgFont',
    },
    typography: {
        padding: theme.spacing(2),
    },
    upload: {
        width: '20px',
        height: '20px',
    },
    bk: {
        height: '100%',
        width: '100%',
        overflowY: 'hidden',
        background: '#ddd',
        display: 'flex',
        padding: '30px',
        // justifyContent: "center",
        // alignItems: "center",
    },
    intro: {
        margin: '20px',
    },
    introdetail: {
        margin: '50px',
    },
    weapp: {
        marginRight: '100px',
        height: '700px',
        width: '300px',
        borderRadius: '30px',
        border: '2px solid #fff',
    },
    top: {
        width: '100%',
        height: '250px',
        left: '0',
        top: '0',
        zIndex: '-100',
        borderRadius: '10% 10% 10% 10%',
        backgroundColor: '#242323',
        backgroundSize: '100% 100%',
        // backgroundSize: "contain"
    },
    topp: {
        paddingTop: '30px',
    },
    toptitle: {
        zIndex: '100',
        paddingTop: '0px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    page: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        marginTop: '-170px',
    },
    pageMessage: {
        width: '280px',
        height: '90px',
        display: 'flex',
        borderRadius: '10px',
        marginBottom: '10px',
        backgroundSize: '100% 100%',
        backgroundColor: '#fff',
    },
    pagePark: {
        width: '280px',
        height: '145px',
        backgroundColor: '#fff',
        borderRadius: '10px',
        marginBottom: '10px',
        backgroundSize: '100% 100%',
    },
    pageParkTitle: {
        width: '70px',
        margin: '10px',
        fontWeight: 'bold',
    },
    pageMall: {
        display: 'flex',
        justifyContent: 'center',
        flex: 1,
    },
    mallbk: {
        height: '50px',
        width: '200px',
    },
    mallPic: {
        textAlign: 'center',
        margin: '5px',
        width: '80px',
        height: '80px',
        borderRadius: '10%',
        backgroundSize: '100% 100%',
        backgroundColor: '#777',
    },
    pageActive: {
        width: '280px',
        height: '150px',
        backgroundColor: '#eee',
        borderRadius: '10px',

        marginBottom: '10px',
    },
    activeImg: {
        width: '280px',
        height: '150px',
        backgroundColor: '#ddd',
        display: 'flex',
        justifyContent: 'center',
        borderRadius: '10px',
        backgroundSize: '100% 100%',
        alignItems: 'center',
        fontSize: '10px',
    },
    button: {
        margin: theme.spacing(1),
    },
    input: {
        display: 'none',
    },
    tabbar: {
        bottom: '0',
        left: '0',
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    member: {
        display: 'flex',
        padding: '0 5px 0 5px',
        justifyContent: 'center',
        alignItems: 'center',
        margin: '22px 5px',
        fontSize: '5px',
        color: '#000',
        fontWeight: '10px',
        height: '20px',
        borderRadius: '10px',
        backgroundColor: '#ddd',
        textAlign: 'center',
    },
    gifts: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        height: '100px',

        marginBottom: '10px',
    },
    gift: {
        margin: '10px',
        width: '80px',
        height: '90px',
        backgroundSize: '100%',
        backgroundColor: '#fff',
        textAlign: 'center',
        paddingTop: '10px',
        borderRadius: '10px',
    },
    fastPoints: {
        width: '50px',
        height: '80px',
        borderRadius: '10px',
        fontSize: '5px',
        textAlign: 'center',
        margin: '5px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    fastImg: {
        width: '40px',
        height: '50px',
    }
}));

export default useStyles;
