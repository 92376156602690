import {
  Button,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  Backdrop,
  Fade,
  Modal,
} from "@mui/material";
import { useEffect, useState } from "react";
import {
  AutocompleteArrayInput,
  BooleanInput,
  Create,
  Datagrid,
  DateTimeInput,
  ImageField,
  ImageInput,
  ListContextProvider,
  NumberInput,
  Pagination,
  ReferenceArrayInput,
  SimpleForm,
  TextField,
  TextInput,
  useGetList,
  useListController,
  useRecordContext,
} from "react-admin";
import { CreateToolbar, TopBarActions } from "../components";
import { styled } from '@mui/material/styles';

const StyledModal = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

const StyledPaper = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  border: '2px solid #000',
  boxShadow: theme.shadows[5],
  padding: theme.spacing(2, 4, 3),
  width: '80%',
  height: '80%',
  overflow: 'auto',
}));

const MemberGiftCreate = (props) => {
  const [coupon, setcoupon] = useState(null);
  const [open, setOpen] = useState(false);
  const [shopOpen, setShopOpen] = useState(false);
  const [cvalue, setCvalue] = useState("gift");
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [showShopIds, setShowShopIds] = useState(true);

  const [sort] = useState({ field: "id", order: "ASC" });
  const { total } = useGetList("coupons", {
    pagination: { page, perPage },
    sort,
    filter: {},
  });
  const optionTextName = (record) => `${record?.name.zh}`;

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleChange = (event) => {
    console.log("🚀 ~ handleChange ~ event:", event);
    setCvalue(event.target.value);
  };

  const SelectButton = () => {
    const record = useRecordContext();

    return (
      <Button
        style={{ width: 50, fontSize: 5 }}
        onClick={() => {
          setOpen(false);
          setShopOpen(false);
          setcoupon(record);
          localStorage.setItem("giftCoupon", JSON.stringify(record));
        }}
        variant="contained"
        color="primary"
        disableElevation
      >
        选择
      </Button>
    );
  };

  useEffect(() => {
    setcoupon(null);
    localStorage.setItem("giftCoupon", null);
  }, [cvalue]);

  return (
    <Create {...props} title="新增礼品" actions={<TopBarActions />}>
      <SimpleForm toolbar={<CreateToolbar />}>
        <div>
          <FormControl component="fieldset" style={{ display: "flex" }}>
            <FormLabel component="legend">优惠券类型选择</FormLabel>
            <RadioGroup
              aria-label="优惠券类型选择"
              name="couponType"
              value={cvalue}
              onChange={handleChange}
            >
              <FormControlLabel value="gift" control={<Radio />} label="默认" />
              <FormControlLabel
                value="shop"
                control={<Radio />}
                label="商铺优惠券"
              />
              <FormControlLabel
                value="supermarket"
                control={<Radio />}
                label="超市优惠券"
              />
            </RadioGroup>
          </FormControl>
          {cvalue === "shop" ? (
            <Button color="primary" onClick={() => setShopOpen(true)}>
              选择商铺优惠券
            </Button>
          ) : null}
          <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            className={StyledModal}
            open={shopOpen}
            onClose={() => setShopOpen(false)}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
              timeout: 500,
            }}
          >
            <Fade in={shopOpen}>
              <div className={StyledPaper}>
                <ListContextProvider
                  label="通用优惠券"
                  title="通用优惠券"
                  value={useListController({
                    basePath: props.match?.path,
                    resource: "coupons",
                    filter: {},
                  })}
                >
                  <Datagrid>
                    <SelectButton />
                    <TextField source="bar_price" label="满" />
                    <TextField source="face_price" label="减" />
                    <TextField source="exchange_points" label="所需积分" />
                  </Datagrid>
                  <Pagination
                    page={page}
                    setPage={setPage}
                    perPage={perPage}
                    setPerPage={setPerPage}
                    total={total}
                  />
                </ListContextProvider>
              </div>
            </Fade>
          </Modal>
        </div>
        <div>
          {cvalue === "supermarket" ? (
            <Button color="primary" onClick={handleOpen}>
              选择超市优惠券
            </Button>
          ) : null}
          <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            className={StyledModal}
            open={open}
            onClose={handleClose}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
              timeout: 500,
            }}
          >
            <Fade in={open}>
              <div className={StyledPaper}>
                <ListContextProvider
                  label=" 超市优惠券"
                  title="超市优惠券"
                  {...props}
                  value={useListController({
                    basePath: props.match?.path,
                    resource: "supermarket-coupons",
                    filter: {},
                  })}
                >
                  <Datagrid>
                    <SelectButton />
                    <TextField source="bar_price" label="满" />
                    <TextField source="face_price" label="减" />
                    <TextField source="exchange_points" label="所需积分" />
                  </Datagrid>
                  <Pagination
                    page={page}
                    setPage={setPage}
                    perPage={perPage}
                    setPerPage={setPerPage}
                    total={total}
                  />
                </ListContextProvider>
              </div>
            </Fade>
          </Modal>
          {cvalue !== "gift" && coupon ? (
            <div>
              满 {coupon?.bar_price} 减 {coupon?.face_price}
            </div>
          ) : null}
        </div>
        <BooleanInput
          label="全部店铺都通用"
          source="available_for_all_shops"
          defaultValue={showShopIds}
          onChange={() => setShowShopIds(!showShopIds)}
          name="showShopIds"
          color="primary"
        />
        {!showShopIds && (
          <ReferenceArrayInput
            source="shop_ids"
            reference="shops"
            required
            fullWidth
          >
            <AutocompleteArrayInput label="商铺" optionText={optionTextName} />
          </ReferenceArrayInput>
        )}

        <ReferenceArrayInput
          source="level_ids"
          reference="member/levels"
          required
          fullWidth
        >
          <AutocompleteArrayInput
            label="领取等级"
            optionText={optionTextName}
          />
        </ReferenceArrayInput>
        <TextInput source="name.zh" label="中文名称" required />
        <TextInput source="name.ug" label="维文名称" required />

        <DateTimeInput source="ended_at" label="有效期限" required />
        <NumberInput source="exchange_points" label="所需积分" required />
        <NumberInput source="limit_per_user" label="每人限领" required />
        <NumberInput source="quantity" label="优惠券总数" required />
        <NumberInput source="market_price" label="市场价格" required />
        <NumberInput source="price" label="价格" required />
        <BooleanInput
          label="状态"
          source="status"
          format={(r) => r === 1}
          parse={(i) => (i === true ? 1 : 0)}
          defaultValue={1}
        />
        <ImageInput
          required
          source="image.zh"
          label="中文图片"
          accept="image/*"
        >
          <ImageField source="src" />
        </ImageInput>
        <ImageInput
          required
          source="image.ug"
          label="维文图片"
          accept="image/*"
        >
          <ImageField source="src" />
        </ImageInput>
        <TextInput
          source="description.zh"
          label="使用规则中文"
          multiline
          fullWidth
          rows={6}
          required
        />
        <TextInput
          source="description.ug"
          label="使用规则维语"
          multiline
          fullWidth
          rows={6}
          style={{ textAlign: "right", direction: "rtl" }}
          required
        />
        <TextInput
          source="gift_description.zh"
          label="中文说明"
          multiline
          fullWidth
          rows={6}
          required
        />
        <TextInput
          source="gift_description.ug"
          label="汉语说明"
          multiline
          fullWidth
          rows={6}
          style={{ textAlign: "right", direction: "rtl" }}
          required
        />
      </SimpleForm>
    </Create>
  );
};

export default MemberGiftCreate;
