import { useMediaQuery } from "@mui/material";
import { styled } from "@mui/material/styles";
import {
  Datagrid,
  DeleteButton,
  EditButton,
  List,
  NumberField,
  ReferenceInput,
  SelectInput,
  SimpleList,
  TextField,
  Create,
  Edit,
  NumberInput,
  SimpleForm,
  TextInput
} from "react-admin";
import { CreateToolbar, TopBarActions } from "../components";
import { currencyProps } from "../constants";

const UgTitle = styled('span')({
  fontFamily: "UgFont"
});

export const CategoriesList = (props) => {
  const isSmall = useMediaQuery("(max-width:600px)");
  return (
    <List {...props} title="店铺分类">
      {isSmall ? (
        <SimpleList
          primaryText={(record) => (
            <span>
              名称 {record.name.zh}
              <span className={UgTitle}> {record.name.ug}</span>
            </span>
          )}
          tertiaryText={(record) =>
            `积分规则（元/积分） ${record.exchange_rate}`
          }
        />
      ) : (
        <Datagrid>
          <TextField source="name.zh" label="中文名称" />
          <TextField
            source="name.ug"
            label="维文名称"
            className={UgTitle}
          />
          <NumberField
            source="exchange_rate"
            label="积分规则（元/积分）"
            {...currencyProps}
          />
          <EditButton />
          <DeleteButton />
        </Datagrid>
      )}
    </List>
  );
};

const CatTitle = ({ record }) => {
  return <span>分类名 {record ? `"${record.name.zh}"` : ""}</span>;
};

export const CategoriesEdit = (props) => {
  return (
    <Edit {...props} title={<CatTitle />} actions={<TopBarActions />}>
      <SimpleForm toolbar={<CreateToolbar />}>
        <TextInput source="name.zh" label="中文名称" />
        <TextInput source="name.ug" label="维文名称" />
        <ReferenceInput
          source="shop_industry_id"
          label="业态"
          reference="shop/industries"
        >
          <SelectInput label="业态" optionText="name.zh" />
        </ReferenceInput>
        <NumberInput source="exchange_rate" label="积分规则（元/积分）" />
      </SimpleForm>
    </Edit>
  );
};

export const CategoriesCreate = (props) => {
  return (
    <Create {...props} actions={<TopBarActions />}>
      <SimpleForm toolbar={<CreateToolbar />}>
        <TextInput source="name.zh" label="中文名称" />
        <TextInput source="name.ug" label="维文名称" />
        <ReferenceInput
          source="shop_industry_id"
          label="业态"
          reference="shop/industries"
        >
          <SelectInput optionText="name.zh" />
        </ReferenceInput>
        <NumberInput source="exchange_rate" label="积分规则（元/积分）" />
      </SimpleForm>
    </Create>
  );
};
