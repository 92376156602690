import * as React from 'react';
import { AppBar, TitlePortal } from 'react-admin';
import Box from '@mui/material/Box';

import Logo from '../logo.svg';
import { Image } from '@mui/icons-material';

export const MyAppBar = () => (
    <AppBar color="primary">
        <TitlePortal />
        <Box flex="1" />
        <Image src={Logo} alt="Logo" />
        <Box flex="1" />
    </AppBar>
);