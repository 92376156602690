
export const filterToQuery = (searchText) => ({ title: searchText });

export const optionText = (record) => `${record?.title.zh} ${record?.title.ug}`;

export const filterOptionsQuery = (searchText) => ({ title: searchText });
export const surveyOptionText = (record) => `${record?.name.zh} ${record?.name.ug}`;


export const adTypes = [
	{ id: 1, name: '跳出广告' },
	{ id: 2, name: '首屏广告' },
	{ id: 3, name: '首页广告' },
];

export const adUrls = [
	{ id: '/pages/articleDetail/index', name: '文章' },
	{ id: '/pages/survey/index', name: '问卷调查' },
	{ id: '/pages/cardefault/index', name: '优惠停车' },
	{ id: '/pages/sign-in-calendar/index', name: '签到页面' },
];